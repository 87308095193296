// import logo from "./logo.svg";
import LightBox from "./Lightbox";
import "./App.css";

function Gagan({images, videos}) {
  return (
    <div className="App">
        <LightBox images={[...images]} videos={[...videos]}/>
    </div>
  );
}

export default Gagan;

import * as React from "react";

import {
  Lightbox,
  ImageSlide,
} from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import image1 from './image1.jpeg'
import PhotoAlbum from "react-photo-album";

function isLoomSlide(slide) {
  return slide.type === "loom";
}

export default function LightBoxGallery({images, videos}) {

  const [index, setIndex] = React.useState(-1);

  const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

  const slides = images.map((image) => {
    const width = image.width * 4;
    const height = image.height * 4;
    return {
      src: image.src,
      width,
      height,
      srcSet: breakpoints.map((breakpoint) => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: image.src,
          width: breakpoint,
          height: breakpointHeight,
        };
      }),
    };
  });

  const videoSlides = videos.map(video => 
    ({
      title: video.title,
      type: "loom",
      width: video.width,
      height: video.height,
      src: video.src,
      thumbnail: video.thumbnail,
      srcSet: breakpoints.map((breakpoint) => {
        const breakpointHeight = Math.round((1920 / 1080) * breakpoint);
        return {
          src: video.thumbnail,
          width: breakpoint,
          height: breakpointHeight,
        };
      }),
    })
  )

  return (
    <>
      <PhotoAlbum
        layout="rows"
        photos={[...slides, ...videoSlides]}
        targetRowHeight={150}
        onClick={({ index: current }) => setIndex(current)}
      />

      <Lightbox
        index={index}
        slides={[...slides, ...videoSlides]}
        plugins={[Thumbnails]}
        render={{
          slide: ({ slide, rect }) =>
            isLoomSlide(slide) ? (
              <iframe
                width={Math.min(
                  slide.width,
                  rect.width,
                  (slide.width * rect.height) / slide.height
                )}
                height={Math.min(
                  slide.height,
                  rect.height,
                  (slide.height * rect.width) / slide.width
                )}
                src={slide.src}
                title={slide.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : undefined,
          thumbnail: ({ slide, ...rest }) =>
            isLoomSlide(slide) ? (
              <ImageSlide slide={{ src: slide.thumbnail }} {...rest} />
            ) : undefined,
        }}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </>
  );
}
